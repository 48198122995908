import CopyToClipboardButton from "./CopyToClipboardButton";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

const Response = ({ text1, text2 }) => {
	return (
		<Box
			sx={{
				"& > :not(style)": { m: 4 },
			}}
		>
			<Typography variant="h4" gutterBottom>
				Image uploaded successfully!
			</Typography>
			<Typography variant="body1" style={{ fontWeight: 600 }} gutterBottom>
				Here is your image link:
			</Typography>
			<Typography variant="body2" gutterBottom>
				{text1}
			</Typography>
			<Stack direction="row" spacing={2} justifyContent="space-around" alignItems="center">
				<Button variant="contained">
					<a href={text1} style={{ color: "black", textDecoration: "none" }} target="_blank" rel="noreferrer">
						View Image
					</a>
				</Button>
                <CopyToClipboardButton textToCopy={text1} />
			</Stack>
			<Divider />
			<Typography variant="body1" style={{ fontWeight: 600 }} gutterBottom>
				Here is your image as an img tag:
			</Typography>
			<Typography variant="body2" gutterBottom>
				{text2}
			</Typography>
			<Stack direction="row" spacing={2} justifyContent="space-around" alignItems="center">
				<CopyToClipboardButton textToCopy={text2} />
			</Stack>
		</Box>
	);
};

export default Response;
