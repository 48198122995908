import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip from "@mui/material/Tooltip";

const CopyToClipboardButton = ({ textToCopy }) => {
	const [copied, setCopied] = useState(false);
	const [open, setOpen] = React.useState(false);

	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleTooltipOpen = () => {
		setOpen(true);
	};

	useEffect(() => {
		setCopied(false);
	}, [textToCopy]);

	const copyToClipboard = async (text) => {
		try {
			await navigator.clipboard.writeText(text);
			setCopied(true);
			// Reset the 'copied' state after 2 seconds
			setTimeout(() => {
				setCopied(false);
			}, 1500);
		} catch (err) {
			console.error("Failed to copy text: ", err);
			setCopied(false);
		}
	};

	const handleClick = () => {
		copyToClipboard(textToCopy);
	};

	return (
		// <ClickAwayListener onClickAway={handleTooltipClose}>
			<div>
				<Tooltip
					PopperProps={{
						disablePortal: true,
					}}
					// onClose={handleTooltipClose}
					open={copied}
					disableFocusListener
					disableHoverListener
					disableTouchListener
					title="Text Copied!"
                    placement="top"
				>
					<Button variant="contained" onClick={handleClick}>
						Copy To Clipboard
					</Button>
				</Tooltip>
			</div>
		// </ClickAwayListener>
	);
};

export default CopyToClipboardButton;
