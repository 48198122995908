import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const UploadImage = ({ handleSubmit, responseData, handleFileChange }) => {
	return (
		<>
			<Box
				sx={{
					"& > :not(style)": { m: 4 },
				}}
			>
				<form onSubmit={handleSubmit}>
					<input type="file" key={responseData.Key} onChange={handleFileChange} accept="image/*" />
					<Button variant="contained" type="submit">
						Upload Image{" "}
					</Button>
				</form>
				{responseData && (
					<Typography variant="h4" gutterBottom>
						{responseData.data}
					</Typography>
				)}
			</Box>
		</>
	);
};

export default UploadImage;
