import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            light: '#33fd83',
            main: '#00fd64',
            dark: '#00b146',
            contrastText: '#000',
        },
        secondary: {
            light: '#afafaf',
            main: '#9b9b9b',
            dark: '#6c6c6c',
            contrastText: '#000',
        },
    },
});

export default theme;