import React, { useState } from "react";
import axios from "axios";

import { CacheProvider } from "@emotion/react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import createEmotionCache from "./lib/createEmotionCache";
import theme from "./lib/theme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import Response from "./components/Response";
import UploadImage from "./components/UploadImage";

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
	// Dealing with the text on load
	const [text1, setText1] = useState("");
	const [text2, setText2] = useState("");
	const [uploaded, setUploaded] = useState(false);

	const handleReset = () => {
		// Clear the text states
		setText1("");
		setText2("");
		setResponseData("");

		// Reset the 'uploaded' state
		setUploaded(false);
	};

	// Handling File Upload
	const [file, setFile] = useState(null);
	const [responseData, setResponseData] = useState("");

	const handleFileChange = (e) => {
		setFile(e.target.files[0]);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setText1("");
		setText2("");

		if (!file) {
			setText1("Please select an image file.");
			return;
		}

		const formData = new FormData();
		formData.append("image", file);

		try {
			const response = await axios.post("/upload", formData, {
				headers: { "Content-Type": "multipart/form-data" },
			});
			setResponseData(response);
			setText1(response.data);
			setText2(`<img src="${response.data}"/>`);
			setUploaded(true);
		} catch (error) {
			setText1("An error occurred while uploading the image.");
			setText2("An error occurred while uploading the image.");
			console.error(error);
		}
	};

	return (
		<CacheProvider value={emotionCache}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Container maxWidth="sm" sx={{ backgroundColor: "primary", paddingTop: "50px" }}>
					<Box>
						<Typography variant="h3" gutterBottom>
							Simple Image Uploader
						</Typography>
						{!uploaded ? (
							<>
                <Divider />
								<UploadImage handleSubmit={handleSubmit} responseData={responseData} handleFileChange={handleFileChange} />
							</>
						) : (
							<>
								<Divider />
								<Response text1={text1} text2={text2} />
                <Divider />
								<Stack direction="row" spacing={2} sx={{ m:4 }} justifyContent="center" alignItems="center">
									<Button variant="contained" size="large" onClick={handleReset}>
										Upload Another Image
									</Button>
								</Stack>
							</>
						)}
					</Box>
				</Container>
			</ThemeProvider>
		</CacheProvider>
	);
}

App.propTypes = {
	Component: PropTypes.elementType.isRequired,
	emotionCache: PropTypes.object,
	pageProps: PropTypes.object.isRequired,
};

export default App;
